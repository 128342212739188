<template>
  <div class="manager-container  module-main-body" v-loading="fullScreenLoading">
    <el-row class="module-content-top-bar">
      <el-col :sm="8" :md="10" :lg="10">
        <h1 class="top-bar-title">{{contentTitle}}</h1>
      </el-col>
      <el-col :sm="16" :md="14" :lg="14">
        <div class="button-bar flex-end">
          <div class="search-bar">
            <el-input
            class="round-input"
            placeholder="Search Ship Name"

            v-model="searchKey"
            @keypress.enter.native="handlerSearchKey">
              <el-button v-if="!toggleIcon" type="primary" slot="append" icon="el-icon-search" @click="handlerSearchKey"></el-button>
              <el-button v-if="toggleIcon" type="primary" slot="append" icon="el-icon-close" @click="onHandlClearSearch"></el-button>
            </el-input>
          </div>
          <div class="button-block" style="padding-top:5px">
            <el-button
              v-if="(showAddAndEdit)"
              class="primary-button"
              :disabled="!selectedCurrentCompany.id"
              icon="el-icon-circle-plus"
              @click="addShip"
            >
              {{ this.$t('add_2') }}
            </el-button>
            <el-button
              v-if="hasPermission($permissionFunctionCode.ADD)"
              class="second-button"
              :disabled="!selectedCurrentCompany.id"
              icon="el-icon-circle-plus"
              @click="inviteVisible=true">
              {{ this.$t('invite') }}
            </el-button>
          </div>
        </div>
      </el-col>
    </el-row>
     <el-row type="flex" justify="space-between" align="middle" class="div-main-body-left-right module-content-main-view">
      <el-col :sm="10" :md="8" :lg="5" class="left" v-if="hasSysAdminPermission">
        <div class="title">
          <base-block-title
            :title="$t('company')"
            :sub-title="companyList.length">
          </base-block-title>
        </div>
        <el-divider class="el-divider"></el-divider>
        <div class="search-bar">
          <el-input
            class="round-input"
            placeholder="Search Company Name"
            v-model="searchCompanyKey"
            @keypress.enter.native="onHandleSearchCompany"
          >
            <el-button v-if="!toggleSearchCompanyIcon" type="primary" slot="append" icon="el-icon-search" @click="onHandleSearchCompany"></el-button>
            <el-button v-if="toggleSearchCompanyIcon" type="primary" slot="append" icon="el-icon-close" @click="onHandlClearSearchCompany"></el-button>
          </el-input>
        </div>
        <div class="content">
          <company-item-new
            v-for="item in companyList"
            :key="item.id"
            :company="item"
            :logoSrc.sync="item.logoSrc"
            :showStatusTage="true"
            :currentCompany="selectedCurrentCompany"
            :active="item.id === selectedCurrentCompany.id"
            @click.native="onClickCompanyItem(item)">
          </company-item-new>
        </div>
      </el-col>
      <el-col :sm="hasSysAdminPermission ? 14:24" :md="hasSysAdminPermission ? 16:24" :lg="hasSysAdminPermission ? 19:24"
        class="right"
        v-if="pageVisible && selectedCurrentCompany.id">
        <div style="display: block; height: calc(100% - 0px); box-shadow: 0 0 5px #eaeaea; border-radius: 10px;">
          <el-table
            ref="refTable"
            v-loading="tableLoading"
            :data="shipList"
            height="100%"
            row-key='id'
            :expand-row-keys="expandedRowsKey"
            @expand-change="handleChange"
            size='small'
          >
            <el-table-column type="expand">
              <template  v-slot="scope">
                <div class="flex-between-row">
                  <span
                    v-for="field in expandCardShipFields"
                    :key="field">
                    <span class="flex-column">
                      <p class="font-small">{{ `${$t(field)}:` }}</p>
                      <strong  class="font-small">{{ `${scope.row[field] || '_'}` }}</strong>
                    </span>
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="120px">
              <template slot="header">
                <span>{{ $t('shipName') }}</span>
              </template>
              <template v-slot="scope">
                 <p class="font-default">{{capitalizeFirstLetter(scope.row.shipName, '_')}}</p>

              </template>
            </el-table-column>
            <el-table-column min-width="60px">
              <template slot="header">
                <span>{{ $t('imo') }}</span>
              </template>
              <template v-slot="scope">
                 <p class="font-default">{{scope.row.imo ? scope.row.imo : '_'}}</p>
              </template>
            </el-table-column>
            <el-table-column min-width="80px">
              <template slot="header">
                <span>{{ $t('licenceNo') }}</span>
              </template>
              <template v-slot="scope">
                <p class="font-default">{{scope.row.licenceNo ? scope.row.licenceNo : '_'}}</p>
              </template>
            </el-table-column>
            <el-table-column min-width="80px">
              <template slot="header">
                <span >{{ $t('registryPort') }}</span>
              </template>
              <template v-slot="scope">
                <p class="font-default">{{scope.row.registryPort ? scope.row.registryPort : '_'}}</p>
              </template>
            </el-table-column>
            <el-table-column min-width="60px">
              <template slot="header">
                <span>{{ $t('type') }}</span>
              </template>
              <template v-slot="scope">
                 <p class="font-default">{{scope.row.type ? scope.row.type : '_'}}</p>
              </template>
            </el-table-column>
            <el-table-column min-width="100px">
              <template slot="header">
                <span>{{ $t('transferredToOrFrom') }}</span>
              </template>
              <template v-slot="scope">
                <div v-if="scope.row.charterId"  class="transfer-to-from">
                    <span v-if="scope.row.isOwner"  class="arrow-icon"><img src="@/assets/images/action_icons/transfer_to.png" alt=""></span>
                  <span  v-else  class="arrow-icon"><img src="@/assets/images/action_icons/transfer_from.png" alt=""></span>
                  <p :style="{color: scope.row.isOwner? '#222BFF': '#FC7C05',fontWeight:'400',display:'block'}" class="font-default"> {{scope.row.transferToOrFrom ? scope.row.transferToOrFrom : ''}}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="100px">
              <template slot="header">
                <span>{{ $t('operator') }}</span>
              </template>
              <template v-slot="scope">
                 <p class="font-default">{{scope.row.operator ? scope.row.operator: '_'}}</p>
              </template>
            </el-table-column>
            <el-table-column width="100px" v-if="hasPermission($permissionFunctionCode.EDIT)">
              <template slot="header">
              </template>
              <template v-slot="scope">
                <div class="flex-between-row">
                  <template>
                    <el-tooltip v-if="scope.row.isOwner" class="item" effect="dark" content="Edit Ship" placement="top-end" >
                      <el-button  @click={editOrViewShip(scope.row,false)} size="small" icon="el-icon-edit" circle ></el-button>
                    </el-tooltip>
                    <el-tooltip v-else class="item" effect="dark" content="View Ship" placement="top-end">
                      <el-button  @click={editOrViewShip(scope.row,true)} size="small" icon="el-icon-view" circle ></el-button>
                    </el-tooltip>
                  </template>
                  <template>
                    <el-tooltip v-if="scope.row.charterId == null" class="item" effect="dark" content="Transfer Ship" placement="top-end">
                      <el-button  @click={showTransferShipDialog(scope.row)} size="small" circle>
                        <span>
                          <img style="height:12px;width:12px; " src="@/assets/images/action_icons/transfer_action_icon.png" alt=""/>
                        </span>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip v-else class="item" effect="dark" content="Stop Current Transfer" placement="top-end">
                      <el-button  @click={stopTransferShip(scope.row)} size="small" icon="el-icon-finished" circle :disabled="!scope.row.isOwner"></el-button>
                    </el-tooltip>
                  </template>
                  <!-- <el-popconfirm
                    placement="left-start"
                    confirm-button-text='Delete'
                    cancel-button-text='No, Thanks'
                    icon="el-icon-info"
                    icon-color="red"
                    title="Are you sure to delete this?"
                    @confirm="deleteShip(scope.row)"
                  >
                    <el-button slot="reference" size="small" icon="el-icon-delete" circle></el-button>
                  </el-popconfirm> -->
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- <div class="pagination-container">
          <el-pagination
            background
            @size-change="handlePageSizeChange"
            @current-change="handlePageChange"
            :page-size.sync="pagination.pageSize"
            :page-sizes="[5, 10, 20, 50, 100]"
            :current-page="pagination.pageNumber"
            layout="total, sizes, prev, pager, next"
            :total="pagination.total"
            />
        </div> -->
      </el-col>
    </el-row>
    <!--编辑船舶信息-->
    <compact-form-add-or-edit
      ref="addOrEdit"
      v-if="editShipVisible && !fullScreenLoading"
      :title="'ship'"
      :visible.sync="editShipVisible"
      :is-view-only.sync="isOnlyViewShipFormField"
      :url-save="saveUrl"
      :url-update="editUrl"
      :fields="shipFormFields"
      :rules="shipFormRules"
      width="70%"
      top="5vh"
      labelWidthCn="100"
      labelWidthEn="200"
      :backupObj="backupObj"
      @closed="hanleClosedEditAndAddPopup"
      @submit-success="getShipListByCompany"
      >
    </compact-form-add-or-edit>
    <el-dialog
      :visible.sync="inviteVisible"
      :close-on-click-modal="false"
      width="40%">
      <base-title slot="title" :text="this.$t('invite')"></base-title>
      <el-form
        class="el-form-item-left-lable"
        style="padding: 0 20px"
        ref="form"
        :model="inviteFormData"
        :rules="inviteFormRules"
        label-width="100px">
        <el-form-item :label="$t('shipName')" prop="shipName">
          <el-input v-model="inviteFormData.shipName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('imo')" prop="imo">
          <el-input v-model="inviteFormData.imo"></el-input>
        </el-form-item>
        <el-form-item :label="$t('licenseNumber')" prop="licenseNumber">
          <el-input v-model="inviteFormData.licenseNumber"></el-input>
        </el-form-item>
        <el-form-item :label="$t('email')" prop="email">
          <el-input v-model="inviteFormData.email"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          round
          @click="inviteVisible = false">
          {{ $t('cancel') }}
        </el-button>
        <el-button
          type="primary"
          round
          @click="inviteShip">
          {{ $t('invite') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="transferShipDialogVisible"
      :close-on-click-modal="false"
      width="40%">
      <div style="margin-bottom:10px"> <span style="font-weight:400; margin-right:10px; font-size: 12">{{$t('imo')}}:</span> <span>{{selectedTransferShip && selectedTransferShip.imo ? selectedTransferShip.imo : '-'}}</span> </div>
      <div style="margin-bottom:10px"> <span style="font-weight:400; margin-right:10px; font-size: 12"> {{$t('shipName')}}:</span> <span>{{selectedTransferShip && selectedTransferShip.shipName ? selectedTransferShip.shipName : '-'}}</span> </div>
      <div style="margin-bottom:20px"> <span style="font-weight:400; margin-right:10px; font-size: 12">{{$t('email')}}:</span> <span>{{selectedTransferShip && selectedTransferShip.email ? selectedTransferShip.email : '-'}}</span> </div>

      <el-form ref="transferShipForm">
        <div style=" width:500px;
          height: 20px;
          display:flex;
          flex-direction: row;
          justify-content: start;
          font-size: 14.5;
          font-weight: 500;"><span style="color:red">* </span> {{ $t('transferTo') }}</div>
        <el-form-item
          prop="transferToOrganizationId"
          :rules="{ required: true, trigger: 'blur' }"
          >
          <el-select
            style="width:100%"
            v-model="transferShipFormData.organizationId"
            filterable
            remote
            reserve-keyword
            placeholder="Please enter a keyword"
            :remote-method="getTransferToCompanyOpts"
            :loading="selectOpstSearchLoading"
            >
            <el-option
              v-for="item in transferToCompanyOpst"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          round
          @click="transferShipDialogVisible = false">
          {{ $t('cancel') }}
        </el-button>
        <el-button
          type="primary"
          round
          @click="handleTransferOrTerminateShip(true)">
         {{ $t('startTransfer') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import baseMixin from '@/mixins/base-mixin'
import baseTableMixin from '@/mixins/base-table-mixin'
import CompactFormAddOrEdit from '@/components/CompactFormAddOrEdit'
import { isValidString, capitalizeFirstLetter, isValidImoNo } from '@/utils/index.js'
import { LICENSE_NUMBKER_RULE } from '@/constants.js'
export default {
  name: 'shipManager',
  mixins: [baseMixin, baseTableMixin],
  components: { CompactFormAddOrEdit },
  data () {
    const rule = {
      pattern: /^[0-9A-Z^$.*+ -?=![\]|/()]*$/,
      message: 'Must be capital letters, numbers or special symbols',
      trigger: 'blur'
    }
    const reqRules = { required: true, message: this.$t('rejectBeEmpty'), trigger: 'change' }

    return {
      reqRules,
      LICENSE_NUMBKER_RULE,
      // height: document.body.clientHeight,
      contentTitle: 'Ship Management',
      shipStampFileList: [],
      expandedRowsKey: [],
      searchKey: '',
      searchCompanyKey: '',
      toggleIcon: false,
      toggleSearchCompanyIcon: false,
      inviteFormData: {
        imo: '',
      },
      inviteFormRules: {
        shipName: [{ required: true, message: this.$t('rejectBeEmpty'), trigger: 'blur' }],
        imo: [
          {
            validator: (rule, value, callback) => {
              if (!(!value || value.trim() === '') && !isValidImoNo(value)) {
                callback('Invalid IMO No.')
              }
              callback()
            },
            trigger: ['change', 'blur']
          }
        ],
        licenseNumber: LICENSE_NUMBKER_RULE,
        // licenseNumber: [{
        //   pattern: /^S[A|B|C|P|R|T]{1} [0-9]{4}[a-zA-Z]{1}$/,
        //   message: 'Invalid license number. Valid format: S[A|B|C|P|R|T] NNNNA',
        //   trigger: 'blur'
        // }],
        email: [
          { required: true, message: this.$t('rejectBeEmpty'), trigger: 'blur' },
          { type: 'email', message: this.$t('emailFormatError'), trigger: 'blur' }
        ]
      },
      emailFormData: {
        email: ''
      },
      expandCardShipFields: [
        'email', 'callSign', 'grt', 'cellPhone', 'satellitePhone', 'exName','sealVerificationReportNo'
      ],
      shipFormFields: [
        {
          fieldName: 'id',
          fieldType: 'invisible'
        },
        {
          fieldName: 'loginable',
          className: 'one-line-item',
          disabled: false,
          formConfig: {
            type: 'switch'
          },
          changeEvent: (value) => {
            const passwordField = this.shipFormFields.find(item => item.fieldName === 'password')
            const emailField = this.shipFormFields.find(item => item.fieldName === 'email')
            passwordField.fieldType = 'invisible'
            emailField.disabled = false
            this.shipFormRules.email.pop()
            this.shipFormRules.password.pop()
            if (value) {
              passwordField.fieldType = null
              emailField.disabled = false
              this.shipFormRules.email.push(this.reqRules)
              this.shipFormRules.password.push(this.reqRules)
            } else {
              passwordField.fieldType = 'invisible'
              emailField.disabled = false
              this.shipFormRules.email.pop()
              this.shipFormRules.password.pop()
            }
          }
        },
        { fieldName: 'owner', changeEvent: (formData, fieldName, value) => this.autoToUpperCase(formData, fieldName, value) },
        {
          label: 'operator',
          fieldName: 'tenantId',
          disabled: true,
          default: '',
          formConfig: {
            type: 'select',
            options: []
          }
        },
        {
          fieldName: 'imo',
          disabled: true
        },

        { fieldName: 'shipName', changeEvent: (formData, fieldName, value) => this.autoToUpperCase(formData, fieldName, value) },
        { fieldName: 'type',
          formConfig: {
            type: 'select',
            options: [
              { value: 'Bulk Carrier' },
              { value: 'General Cargo' },
              { value: 'Container Ship' },
              { value: 'Reefer' },
              { value: 'Ro-Ro' },
              { value: 'Chemical Tanker' },
              { value: 'Oil Tanker' },
              { value: 'Product Tankers' },
              { value: 'LPG Tanker' },
              { value: 'Bunker Barge' },
              { value: 'Livestock Carrier' },
              { value: 'Heavy Lift' },
              { value: 'Tugs' },
              { value: 'Vehicle Carrier' },
              { value: 'LNG Tanker' }
            ]
          }
        },
        { fieldName: 'callSign', changeEvent: (formData, fieldName, value) => this.autoToUpperCase(formData, fieldName, value) },
        {
          fieldName: 'grt',
          formConfig: {
            type: 'inputNumber'
          }
        },
        { fieldName: 'registryPort', changeEvent: (formData, fieldName, value) => this.autoToUpperCase(formData, fieldName, value) },
        { fieldName: 'licenceNo', changeEvent: (formData, fieldName, value) => this.autoToUpperCase(formData, fieldName, value) },
        { fieldName: 'satellitePhone' },
        { fieldName: 'exName', changeEvent: (formData, fieldName, value) => this.autoToUpperCase(formData, fieldName, value) },
        { fieldName: 'sealVerificationReportNo', changeEvent: (formData, fieldName, value) => this.autoToUpperCase(formData, fieldName, value) },
        { fieldName: 'meterSerialNo', changeEvent: (formData, fieldName, value) => this.autoToUpperCase(formData, fieldName, value) },
        {
          fieldName: 'email'
          // disabled: true
        },
        {
          fieldName: 'password',
          formConfig: {
            type: 'password'
          }
        },
        {
          fieldName: 'cellPhone',
          className: 'selet-input',
          formConfig: {
            type: 'phone'
          }
        },
        {
          fieldName: 'stampId',
          className: 'upload-preview',
          default: '',
          formConfig: {
            type: 'stamp-upload',
            desc: 'Upload Image',
            message: 'Only JPEG/PNG can be uploaded.',
            fileList: null,
            handleSuccess: this.handleSuccess,
            handleRemove: this.handleRemove,
            beforeRemove: this.beforeRemove
          }
        }
      ],
      shipFormRules: {
        owner: [rule],
        tenantId: [
          { required: true, message: this.$t('rejectBeEmpty'), trigger: 'change' }
        ],
        imo: [
          // { required: false, message: this.$t('rejectBeEmpty'), trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              if (!(!value || value.trim() === '') && !isValidImoNo(value)) {
                callback('Invalid IMO No.')
              }
              callback()
            },
            trigger: 'blur'
          }
        ],
        email: [
          { required: false, trigger: 'blur' }
          // { type: 'email', message: this.$t('emailFormatError'), trigger: 'blur' }
        ],
        shipName: [
          { required: true, message: this.$t('rejectBeEmpty'), trigger: 'blur' },
          rule
        ],
        type: [
          { required: false, trigger: 'change' }
          // { required: true, message: this.$t('rejectBeEmpty'), trigger: 'change' }
        ],
        callSign: [rule],
        registryPort: [rule],
        licenceNo: [
          { required: false, message: this.$t('rejectBeEmpty'), trigger: 'change' },
          {
            pattern: /^S[A|B|C|P|R|T]{1} [0-9]{4}[a-zA-Z]{1}$/,
            message: 'Invalid license number. Valid format: S[A|B|C|P|R|T] NNNNA',
            trigger: 'blur'
          }
        ],
        sealVerificationReportNo: [
          { required: true, message: this.$t('rejectBeEmpty'), trigger: 'blur' },
          rule
        ],
        meterSerialNo: [
          { required: true, message: this.$t('rejectBeEmpty'), trigger: 'blur' },
          rule
        ],
        password: [
          { pattern: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(-)_+=~`]).{8,30})/,
            message: 'Password at least one number, uppercase, lowercase, special characters, 8 to 30 bits in length',
            trigger: 'blur'
          }
        ],
        grt: [
          { type: 'number', message: 'Must be a number', trigger: 'blur' }
        ]
      },
      companyList: [],
      selectedCurrentCompany: {},
      currentShip: {},
      shipList: [],
      inviteVisible: false,
      editShipVisible: false,
      transferToCompanyList: [],
      pageVisible: true,
      editUrl: this.$apis.editShip,
      pagination: {
        pageSize: 50,
        pageNumber: 1,
        total: 0
      },
      fullScreenLoading: false,
      selectOpstSearchLoading: false,
      selectedTransferShip: null,
      transferToCompanyOpst: [],
      transferShipFormData: {
        organizationId: null
      },
      transferShipDialogVisible: false,
      isOnlyViewShipFormField: false,
      backupObj: { }
    }
  },

  computed: {
    ...mapState(["currentCompany", "permissions"]),
    shipFields () {
      /**
       * 需要展示的ship字段
       */
      const fields = this.shipFormFields.map(item => item.fieldName)
      if (this.selectedCurrentCompany.id === 'noCompany') {
        return fields.filter(item => !['id', 'password', 'tenantId', 'loginable'].includes(item))
      }
      return fields.filter(item => !['id', 'password', 'tenantId', 'owner', 'loginable'].includes(item))
    },
    ...mapState({
      roles: 'roles',
      permissions: 'permissions'
    }),
    hasSysAdminPermission () {
      return this.permissions.hasSysAdminPermission
    },
    hasCompanyManagerPermission () {
      return this.permissions.hasCompanyManagerPermission
    },
    hasCompanyAdminPermission () {
      return this.permissions.hasCompanyAdminPermission
    },
    showAddAndEdit () {
      /**
       * 需要展示add按钮
       */
      return this.hasSysAdminPermission || this.hasCompanyAdminPermission || this.hasCompanyManagerPermission
    },


  },

  created () {
    this.saveUrl = this.$apis.saveShip
    this.deleteUrl = this.$apis.deleteShip
    this.getCompanies()
  },
  methods: {
    capitalizeFirstLetter,
    isValidString,

    onClickCompanyItem (item) {
      this.selectedCurrentCompany = item;
      this.getShipListByCompany()
    },
    /**
     * 根据当前用户获取企业列表
     */
    getCompanies () {
      const fetchFiles = async () => {
        if (this.companyList.length <= 0) return
        const files = this.companyList.filter(d => d?.bdnLogo?.length > 0).map(d => d.bdnLogo[0])
        if (files.length <= 0) return
        this.downloadMultipleFileMixin(files).then(filesRes => {
          if (filesRes && filesRes !== '') {
            const logoMap = new Map(Object.entries(filesRes))
            this.companyList.forEach(company => {
              if (company?.bdnLogo?.length > 0) {
                company.logoSrc = company.bdnLogo[0].id ? logoMap.get(company.bdnLogo[0]?.id) : null
              }
            })
          }
        })
      }

      return new Promise((resolve, reject) => {
        this.fullScreenLoading = true
        if (this.hasSysAdminPermission) {
          this.$request.get({
            url: `${this.$apis.companyBaseUrl}?pageNumber=1&pageSize=99999&offline=false`,
          }).then(res => {
            if (res?.code === 1000) {
              this.companyList = res?.data?.records || []
              fetchFiles().then(() => {
                this.fullScreenLoading = false
                this.companyList.unshift({
                  id: 'noCompany',
                  companyName: 'Other Ships',
                  tenantId: 'noCompany',
                  active: 1,
                  enable: true
                })
                resolve()
              })
            }
          })
        }
        else {
          const { currentCompany: globalCurrentCompany } = this.$store.state
          this.companyList = globalCurrentCompany ? [globalCurrentCompany] : []
          fetchFiles().then(() => {
            this.fullScreenLoading = false
            resolve()
          })
        }
      })
    },

    getShipListByCompany () {
      /**
       * 根据企业获取船舶列表
       */
      this.fullScreenLoading = true
      this.$request.get({
        url: `${this.$apis.shipBaseUrl}?pageNumber=1&pageSize=999999&organizationId=${this.selectedCurrentCompany.id}`
      }).then(async res => {
        if (res?.code === 1000) {
          try {
            await this.getTransferToCompanyOpts('', true)
          } catch (error) {
          }
          this.shipList = res.data.records || []
          this.shipList.forEach(ship => {
            this.companyList.forEach(company => {
              if (ship.tenantId === company.id) {
                ship.operator = company.companyName
              }
            })

            if (ship.charterId) {
              const isOwner = this.selectedCurrentCompany.id === ship.tenantId
              const transferToOrFrom = this.transferToCompanyList.find(item => item.value === (isOwner ? ship.charterId : ship.tenantId))
              ship.transferToOrFrom = transferToOrFrom?.label
              ship.isOwner = isOwner
            } else {
              ship.isOwner = true
            }
          })
          this.pagination.total = this.shipList.length
        }
        this.fullScreenLoading = false
      }).finally(() => {
        this.fullScreenLoading = false
      })
    },

    /**
     * 邀请船舶注册
     */
    inviteShip () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = { ...this.inviteFormData }
          data.tenantId = this.selectedCurrentCompany.id
          this.$request.post({
            url: this.$apis.inviteShip,
            data
          }).then(res => {
            if (res?.code === 1000) {
              this.inviteVisible = false
              this.$message.success(this.$t('invitedMailSentSuccess'))
            }
          })
        }
      })
    },

    /**
     * 增加无企业的船舶
     */
    addShip () {
      this.shipFormFields[0].default = null
      this.shipFormFields.forEach(item => {
        item.default = null
        if (['imo', 'email'].includes(item.fieldName)) {
          item.disabled = false
        }
        if (item.fieldName === 'password') {
          item.fieldType = 'invisible'
        }
        if (item.fieldName === 'loginable') {
          item.default = false
          item.disabled = false
          item.fieldType = null
        }
        if (item.fieldName === 'tenantId') {
          item = this.addOptions(item)
          if (this.roles[0].code === this.$roleCode.systemAdmin) {
            item.disabled = false
          } else {
            item.default = this.selectedCurrentCompany.id
          }
        }
        if (item.fieldName === 'stampId') {
          item.formConfig.fileList = null
        }
      })
      this.editShipVisible = true
    },
    /**
     * 编辑/查看船舶信息
     */
    editOrViewShip  (ship, isViewOnly) {
      this.shipFormFields.forEach(item => {
        item.default = ship[item.fieldName]
        if (['imo'].includes(item.fieldName)) {
          item.disabled = isViewOnly
        }
        if (item.fieldName === 'loginable') {
          item.disabled = item.default
        }
        if (item.fieldName === 'password') {
          const loginAble = this.shipFormFields.find(item => item.fieldName === 'loginable')
          item.fieldType = loginAble.default ? null : 'invisible'
          item.default = undefined
        }
        if (item.fieldName === 'tenantId') {
          item = this.addOptions(item)
          if (this.roles[0].code === this.$roleCode.systemAdmin) {
            item.disabled = false
            item.default = this.selectedCurrentCompany.id
          } else {
            item.default = this.selectedCurrentCompany.id
          }
        }
        // if (item.fieldName === 'imo') {
        //   if (this.roles[0].code === this.$roleCode.systemAdmin) {
        //     item.disabled = false
        //   }
        // }
        if (item.fieldName === 'stampId') {
          if (ship.stampId) {
            this.$request.post({
              url: this.$apis.getFileUrl,
              data: [ship.stampId]
            }).then(res => {
              if (res?.code === 1000) {
                item.formConfig.fileList = [{
                  name: 'Ship Stamp',
                  url: res.data[ship.stampId],
                  id: ship.stampId
                }]
              }
            })
          } else {
            item.formConfig.fileList = []
          }
          item.default = ship.stampId ? ship.stampId : null
        }
        if (item.fieldName === 'email') {
          if (this.roles[0].code === this.$roleCode.systemAdmin || this.roles[0].code === this.$roleCode.companyAdmin) {
            item.disabled = false
          }
        }
        if (!ship.isOwner) {
          this.isOnlyViewShipFormField = true
        }
      })
      this.backupObj = {
        imo: ship.imo,
        licenceNo: ship.licenceNo,
        shipName: ship.shipName
      }
      this.editShipVisible = true
    },

    /**
     * 删除船舶
     */
    deleteShip (ship) {
      this.$confirm(this.$t('deletePermanent'), this.$t('deleteShip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.$request.post({
          url: this.$apis.deleteShip,
          data: [ship.id]
        }).then(res => {
          if (res?.code === 1000) {
            this.getShipListByCompany()
            this.$message.success(this.$t('dataDeleteSuccess'))
          }
        })
      }).catch(() => {
      })
    },

    /**
     * 获取企业状态颜色
     */
    getCompanyStatusColor (company) {
      if (company.auditStatus === 1) {
        return {
          backgroundColor: '#E6A23C'
        }
      }
      if (company.enable) {
        return {
          backgroundColor: '#67C23A'
        }
      }
      return {
        backgroundColor: '#F56C6C'
      }
    },
    onHandleSearchCompany () {
      const key = this.searchCompanyKey
      this.getCompanies().then(() => {
        if (this.isValidString(key) && this.companyList?.length > 0) {
          const companyListSearched = this.companyList.filter(d => d.companyName.toLowerCase().includes(this.searchCompanyKey.toLowerCase()))
          this.companyList = companyListSearched
          this.toggleSearchCompanyIcon = !this.toggleSearchCompanyIcon
        } else {
          this.toggleSearchCompanyIcon = false
        }
      })
    },
    onHandlClearSearchCompany () {
      this.getCompanies().then(() => {
        this.toggleSearchCompanyIcon = !this.toggleSearchCompanyIcon
        this.searchCompanyKey = ''
      })
    },

    /**
     * 通过关键字查询ship name
     */
    handlerSearchKey () {
      if (this.searchKey) {
        this.shipList = this.shipList.filter(item => {
          return item.shipName.includes(this.searchKey.toUpperCase())
        })
      } else {
        this.getShipListByCompany()
      }
    },
    onHandlClearSearch () {
      this.getCompanies().then(() => {
        this.toggleIcon = !this.toggleIcon
        this.searchCompanyKey = ''
      })
    },
    hanleClosedEditAndAddPopup () {
      this.editShipVisible = false
    },

    /**
     * 添加选择框options
     */
    addOptions (item) {
      item.formConfig.options = this.companyList
      item.formConfig.options.forEach(company => {
        company.label = company.companyName
        company.value = company.id
      })
      return item
    },

    /**
     * upload file 相关功能函数
     */
    handleSuccess (response, file) {
      if (response.code === 1000) {
        this.shipFormFields.forEach(item => {
          if (item.fieldName === 'stampId') {
            const shipStampFile = response.data
            shipStampFile.url = file.url
            item.formConfig.fileList = [shipStampFile]
            item.default = item.formConfig.fileList && item.formConfig.fileList[0].id
          }
        })
      }
    },
    handleRemove (file, fileList) {
      this.shipFormFields.forEach(item => {
        if (item.fieldName === 'stampId') {
          item.formConfig.fileList = null
          item.default = null
        }
      })
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`Are you sure you want to remove ${file.name}？`)
    },
    // table
    handleChange (selection, expandedRows) {
      if (expandedRows.length === 0) {
        this.expandedRowsKey = []
      } else if (expandedRows.length > 1) {
        this.expandedRowsKey = [selection.id]
      } else {
        this.expandedRowsKey.push(selection.id)
      }
    },
    handlePageSizeChange (pageSize) {
      this.getShipListByCompany(pageSize, 0)
    },
    handlePageChange (pageNumber) {
      if (this.pagination.pageNumber !== pageNumber) {
        this.pagination.pageNumber = pageNumber
        this.getShipListByCompany(this.pagination.pageSize, this.pagination.pageNumber - 1)
      }
    },
    autoToUpperCase (formData, fieldName, value) {
      if (!value) return
      this.$set(formData, fieldName, value.toUpperCase())
    },
    /**
     * Transfer Ship 相关功能函数
     */
    showTransferShipDialog (ship) {
      this.transferShipDialogVisible = true
      this.selectedTransferShip = ship
    },
    async handleTransferOrTerminateShip (isStart) {
      const data = {
        shipId: this.selectedTransferShip?.id
      }
      const url = isStart ? this.$apis.shipTransfer : this.$apis.shipTerminate
      if (isStart) data.charterId = this.transferShipFormData.organizationId
      try {
        const res = await this.$request.post({
          url,
          data: [data]
        })
        if (res?.code === 1000) {
          this.$message.success(`${isStart ? 'Transfer' : 'Terminate'} Successfully`)
        }
      } catch (e) {
        this.$message.error(`${isStart ? 'Transfer' : 'Terminate'} Ship Failed`)
      } finally {
        this.getShipListByCompany()
        if (this.transferShipDialogVisible) this.transferShipDialogVisible = !this.transferShipDialogVisible
      }
    },
    stopTransferShip (ship) {
      this.selectedTransferShip = ship
      this.$confirm('Are you sure to stop current transfer?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.handleTransferOrTerminateShip(false)
      }).catch(() => {
      })
    },
    /**
     * Get Transfer To Company
     * 1.only online mode Company
     * 2.can serch by server side
     */
    async getTransferToCompanyOpts (searchKey, isGetAll) {
      this.selectOpstSearchLoading = true
      const hasSearchKey = searchKey !== null && searchKey.trim() !== ''
      const pageSize = hasSearchKey || isGetAll ? '999' : '10'
      let url = `${this.$apis.sharedCompanyBaseUrl}?pageNumber=1&pageSize=${pageSize}&offline=false`
      if (hasSearchKey) {
        url += `&companyName=${searchKey}`
      }
      try {
        const res = await this.$request.get({
          url
        })
        if (res?.code === 1000 && res.data?.records?.length > 0) {
          this.transferToCompanyOpst = res.data.records.filter(item => item.id !== this.selectedCurrentCompany.id)?.map(item => {
            return {
              label: item.companyName,
              value: item.id
            }
          })
          if (isGetAll) {
            this.transferToCompanyList = this.transferToCompanyOpst
          }
        } else {
          this.transferTocompanyOpst = []
        }
      } catch (e) {
        this.$message.error('Error retrieving list of companies')
      } finally {
        this.selectOpstSearchLoading = false
      }
    }

  },

  watch: {
    currentCompany: {
      handler (value) {
        if (value) {
          this.selectedCurrentCompany = value
          // 无企业的船，则不用获取功能点数据
          if (value.id !== 'noCompany') {
            const { id: userId } = this.$store.state.user
            this.getFunctionsByUser(userId, value.id)
            this.getShipListByCompany()
          }
        }
      },
      immediate: true
    },

    inviteVisible (newValue) {
      if (newValue) {
        this.inviteFormData = {
          imo: '',
          email: ''
        }
      } else {
        this.backupObj = {}
      }
    }

  }
}
</script>

<style lang="scss" scoped>
@import "../../systemManager/companyManager/index.scss";

.transfer-to-from {
    display: flex;
    justify-items: center;
    justify-content: left;
  .arrow-icon{
    height: 100%;
    display: block;
    margin: auto 0;
  }
}

</style>
